<template>
    <div>
      <c-search-box ref="searchbox" @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd"  @datachange="datachange"/>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              type="edit"
              required
              codeGroupCd="AIR_INSPECT_TYPE_CD"
              itemText="codeName"
              itemValue="code"
              label="측정구분"
              name="airInspectTypeCd"
              v-model="searchParam.airInspectTypeCd"
              @datachange="datachange">
            </c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-datepicker
              v-show="isYear"
              required
              :range="true"
              :clearable="false"
              label="LBLPERIOD"
              type="year"
              name="period"
              defaultStart="-4y"
              defaultEnd="0M"
              v-model="period2"
              @datachange="datachange"
            />
            <c-datepicker
              v-show="!isYear"
              required
              :range="true"
              :clearable="false"
              label="LBLPERIOD"
              name="period"
              type="month"
              defaultStart="-6M"
              defaultEnd="0M"
              v-model="period"
              @datachange="datachange"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-text
              :afterIcon="
                [
                  {
                    name: 'search',
                    click: true,
                    callbackName: 'searchOutlet',
                    color: 'teal',
                  },
                  {
                    name: 'close',
                    click: true,
                    callbackName: 'removeOutlet',
                    color: 'red',
                  },
                ]
              "
              :editable="editable"
              :readonly="true"
              label="배출구"
              name="envAirMstOutletName"
              v-model="searchParam.envAirMstOutletName"
              @searchOutlet="searchOutlet"
              @removeOutlet="removeOutlet"
              @dataChange="datachange"
            >
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              v-if="searchParam.envAirMstOutletName"
              :comboItems="inspectItems"
              type="search"
              itemText="envAirMstInspectItemName"
              itemValue="envAirMstInspectItemId"
              name="envAirMstInspectItemId"
              label="검사항목"
              v-model="searchParam.envAirMstInspectItemId"
              @datachange="datachange2">
            </c-select>
          </div>
        </template>
      </c-search-box>
      <div class="row">
        <div class="float-right gridbtntop" style="margin-left:auto;">
          <q-btn-group outline >
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
        <div class="col-12" style="background:#fff;">
          <apexchart 
            ref="chart1" 
            type="line"
            :height="chart.height" 
            :options="chart.chartOptions" 
            :series="chart.series"></apexchart>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'self-air-measure-graph',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          mainPeriod: [],
          mainPlantCd: null,
        }
      },
    },
  },
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      fullscreen: false,
      searchParam: {
        plantCd: '',
        startYmd: '',
        endYmd: '',
        airInspectTypeCd: 'AITC000001',
        envAirMstOutletId: '',
        envAirMstOutletName: '',
        envAirMstInspectItemId: null,
        envAirMstInspectItemName: '',
      },
      year: [],
      chart: {
        height: '600',
        chartOptions: {
          title: {
            text: '검사항목별 평균 추이분석'
          },
          annotations: {
            yaxis: [],
          },
         // 그래프 설정
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            min: -10,
            max: 10,
            title: {
              text: '항목수치'
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          fill: {
            opacity: 1
          },
          markers: {
            size: 1,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          },
        },
        series: [
          {
            name: "항목수치",
            data: []
          }
        ],
        chartWidth: '80%',
      },
      period: [],
      period2: [],
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
      isYear: false,
      inspectItems: [],
      itemListUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      // 메인에서 호출한 팝업파라미터
      if (this.popupParam.mainPlantCd) {
        this.searchParam.plantCd = (!this.popupParam.mainPlantCd || this.popupParam.mainPlantCd.length > 3 ? null : this.popupParam.mainPlantCd);
      }
      this.listUrl = selectConfig.env.air.self.measure.graph.url;
      this.itemListUrl = selectConfig.env.air.mst.inspect.plant.url;
      this.setSize();
      this.getItems();
      this.getList();
    },
    getItems() {
      this.$http.url = this.itemListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.searchParam.plantCd,
        airInspectTypeCd: this.searchParam.airInspectTypeCd,
      };
      this.$http.request((_result) => {
        this.inspectItems = _result.data;
      },);
    },
    getList() {
      this.chart.chartOptions.annotations.yaxis = [];
      delete this.chart.chartOptions.yaxis.max;
      if (this.searchParam.period !== null || this.searchParam.period2 !== null) {
        if (!this.isYear) {
          this.searchParam.startYmd = this.period[0];
          this.searchParam.endYmd = this.period[1];
        } else if (this.isYear) {
          this.searchParam.startYmd = this.period2[0];
          this.searchParam.endYmd = this.period2[1];
        }
        if (this.searchParam.airInspectTypeCd !== null) {
            this.$http.url = this.listUrl;
            this.$http.type = 'GET';
            this.$http.param = this.searchParam;
            this.$http.request((_result) => {
              if (this.searchParam.envAirMstOutletId) {
                this.chart.series = [];
                let xaxis = []
                let seriesData = [];
                this.$_.forEach(_result.data, _item => {
                  xaxis.push(_item.envAirSelfMeasureMstName)
                  if (_item.envAirMstInspectItemIds) {
                    let count =0;
                    let ids = this.$_.split(_item.envAirMstInspectItemIds, ',')
                    let names = this.$_.split(_item.envAirMstInspectItemNames, ',')
                    let measures = this.$_.split(_item.measures, ',')
                  
                    this.$_.forEach(ids, __item => {
                      seriesData.push({
                        envAirSelfMeasureMstId: _item.envAirSelfMeasureMstId,
                        envAirMstInspectItemId: __item,
                        name: names[count],
                        measure: measures[count],
                      })
                      if (this.$_.findIndex(this.chart.series, {envAirMstInspectItemId: __item}) === -1) {
                        this.chart.series.push({
                          envAirMstInspectItemId: __item,
                          name: names[count],
                          data: [],
                        })
                      }
                      count ++;
                    })
                  }
                })
                this.$set(this.chart.chartOptions.title, 'text', '배출구별 검사항목 추이분석(' + this.searchParam.envAirMstOutletName + ')')
                this.chart.chartOptions.xaxis.categories = xaxis
                let minMeasure = 0;
                let maxMeasure = 0;
                this.$_.forEach(this.chart.series, _item => {
                  let realSeriesData = this.$_.filter(seriesData, {envAirMstInspectItemId: _item.envAirMstInspectItemId});
                  this.$_.forEach(realSeriesData, __item => {
                    if (minMeasure > Number(__item.measure)) {
                      minMeasure = Number(__item.measure)
                    }
                    if (maxMeasure < Number(__item.measure)) {
                      maxMeasure = Number(__item.measure)
                    }
                    _item.data.push(Number(__item.measure))
                  })
                })
                if (this.chart.series.length > 0) {
                  if (this.searchParam.envAirMstInspectItemId) {
                    this.$set(this.chart.chartOptions.title, 'text', '배출구별 검사항목 추이분석(' + this.searchParam.envAirMstOutletName + ' / '+ this.searchParam.envAirMstInspectItemName +')')
                    let anos  = this.$_.filter(this.inspectItems, {envAirMstInspectItemId : this.searchParam.envAirMstInspectItemId });
                    let _datas = [];
                    this.$_.forEach(this.chart.series, _items => {
                      this.$_.forEach(_items.data, _item => {
                        _datas.push(parseFloat(_item))
                      })
                    })
                    let _legalStandard = anos[0].legalStandard;
                    let _innerStandard = anos[0].innerStandard;
                    if (anos.length > 0) {
                      if (minMeasure > Number(_legalStandard)) {
                        minMeasure = Number(_legalStandard)
                      }
                      if (maxMeasure < Number(_legalStandard)) {
                        maxMeasure = Number(_legalStandard)
                      }
                      if (minMeasure > Number(_innerStandard)) {
                        minMeasure = Number(_innerStandard)
                      }
                      if (maxMeasure < Number(_innerStandard)) {
                        maxMeasure = Number(_innerStandard)
                      }
                      this.chart.chartOptions.annotations.yaxis = [
                        {
                          y: _legalStandard,
                          borderColor: '#e53935',
                          label: {
                            borderColor: '#e53935',
                            style: {
                              color: '#fff',
                              background: '#e53935',
                            },
                            text: '법적기준 : ' + String(_legalStandard) + '',
                          }
                        },
                        {
                          y: _innerStandard,
                          borderColor: '#ffd600',
                          label: {
                            borderColor: '#ffd600',
                            style: {
                              color: '#000',
                              background: '#ffd600',
                            },
                            text: '내부기준 : ' + String(_innerStandard) + '',
                          }
                        },
                      ];
                    }
                  } else {
                    this.chart.chartOptions.annotations.yaxis = [];
                  }
                  this.chart.chartOptions.yaxis.min = minMeasure;
                  this.chart.chartOptions.yaxis.max = maxMeasure;
                } else {
                  this.chart.chartOptions.annotations.yaxis = [];
                  delete this.chart.chartOptions.yaxis.max;
                  delete this.chart.chartOptions.yaxis.min;
                }
              } else {
                this.chart.series = [];
                let xaxis = []

                // series.data length 구하기
                let len = _result.data.filter((_item, _idx) => {
                  return _result.data.findIndex((__item, __idx) => {
                    return _item.envAirSelfMeasureMstId === __item.envAirSelfMeasureMstId
                  }) === _idx;
                }).length
                
                this.$_.forEach(_result.data, _item => {
                  let idx = xaxis.indexOf(_item.envAirSelfMeasureMstName) 
                  if (idx === -1) {
                    xaxis.push(_item.envAirSelfMeasureMstName)
                  }
                  if (_item.envAirMstInspectItemIds) {
                    if (this.$_.findIndex(this.chart.series, { envAirMstInspectItemId: _item.envAirMstInspectItemIds }) === -1) {
                      this.chart.series.push({
                        envAirMstInspectItemId: _item.envAirMstInspectItemIds,
                        name: _item.envAirMstInspectItemNames,
                        data: Array.from({length : len}, () => 0),
                      })
                    }

                    this.chart.series[this.$_.findIndex(this.chart.series, {envAirMstInspectItemId: _item.envAirMstInspectItemIds })]
                    .data[idx] = _item.measures
                    
                  }
                })
                this.$set(this.chart.chartOptions.title, 'text', '검사항목별 평균 추이분석')
                this.chart.chartOptions.xaxis.categories = xaxis
              }

              setTimeout(() => {
                this.chart.chartWidth = '100%';
                this.$refs['chart1'].refresh();
              }, 200);
            },);
          } else {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message:
              '측정구분을 선택하세요',
              type: 'warning', // success / info / warning / error
            });
          }
        } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message:
          '기간을 선택하세요',
          type: 'warning', // success / info / warning / error
        });
      }
    },
    datachange() {
      this.searchParam.envAirMstInspectItemId = null;
      if (this.searchParam.airInspectTypeCd === 'AITC000001') {
        this.isYear = false;
      } else {
        this.isYear = true;
      }
      this.$http.url = this.itemListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.searchParam.plantCd,
        airInspectTypeCd: this.searchParam.airInspectTypeCd,
      };
      this.$http.request((_result) => {
        this.inspectItems = _result.data;
        this.getList()
      },);
    },
    datachange2(_data) {
      this.$set(this.searchParam, 'envAirMstInspectItemName', _data.label)
      this.getList()
    },
    searchOutlet() {
      this.popupOptions.title = "배출구 선택"; // 배출구 선택
      this.popupOptions.param = {
        type: "single",
        plantCd: this.searchParam.plantCd,
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/env/air/mst/airMaster01Pop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.searchParam.envAirMstInspectItemId = null;
      if (data && data.length > 0) {
        this.searchParam.envAirMstOutletId = data[0].envAirMstOutletId;
        this.searchParam.envAirMstOutletName = data[0].envAirMstOutletName;
        this.getList()
      }
    },
    removeOutlet() {
      this.searchParam.envAirMstOutletId = '';
      this.searchParam.envAirMstOutletName = '';
      this.searchParam.envAirMstInspectItemId = null;
      this.getList()
    },
    setSize() {
      let tempHeight = (window.innerHeight - (300));
      if (tempHeight < 300) {
        tempHeight = 300;
      }
      this.$set(this.chart, 'height', tempHeight)
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  padding: 0px !important
.widget-chart
  padding: 5px !important
.widget-chart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>